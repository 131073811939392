<template>
	<div>
		<div class="container csc">
			<div class="top-cover">
				<img src="../../assets/home/cover.png" alt>
			</div>
			<div class="top csc">
				<div class="cont">
					<el-input placeholder="请输入要搜索的链接" v-model="input" clearable></el-input>
					<div class="search-btn" @click="$go('rebate')"></div>
					<search-price :options="data.destination"></search-price>
				</div>
			</div>
			<!-- 下单系统 -->
			<div class="order csc">
				<t-title title="下单系统" eng="ORDER SYSTEM" />
				<div class="rac wrap">
					<div class="order-item ccc" v-for="(item, index) in transports" :key="index"
						@click="toOrderSystem(index)">
						<el-image style="width: 110px; height: 110px" :src="item.icon"></el-image>
						<div class="desc cac">
							<div class="txt">{{item.txt}}</div>
							<div>{{item.engTxt}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 操作流程 -->
			<div class="operation csc">
				<t-title title="操作流程" eng="OPERATION PROCESS" />
				<div class="section">
					<div class="card rsc" v-for="item in data.operation_article">
						<div class="item cover">
							<img style="width: 100%;" :src="item.thumb">
						</div>
						<div class="item cont">
							<div class="title">{{item.title}}</div>
							<div>{{item.intro}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 行业动态 -->
			<div class="dynamic csc">
				<t-title title="行业动态" eng="INDUSTRY DYNAMIC" color="#fff" bgColor="#fff" />

				<div class="carousel">
					<el-carousel :interval="6000" type="card" height="350px" :autoplay="false"
						indicator-position="outside">
						<el-carousel-item v-for="item in data.industry_article" :key="item.id">
							<!-- <h3 class="medium">{{ item }}</h3> -->
							<div class="rbc df">
								<el-image style="width: 350px; height: 100%" :src="item.thumb" fit="cover"></el-image>
								<div class="css card-cont" style="flex: 1;">
									<div class="card-title text_over_2line">{{item.title}}</div>
									<div style="margin-top: 10px;" class="desc">{{item.intro}}</div>
									<div @click="$go(`article?id=${item.id}`)" class="btn">了解更多</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TTitle from 'components/title'

	export default {
		components: {
			TTitle,
		},
		data() {
			return {
				input: "",
				num: '',
				curIndex: 0,
				transports: [{
					icon: require('@/assets/home/air_freight.png'),
					txt: '空运',
					engTxt: 'Air freight'
				}, {
					icon: require('@/assets/home/shipping.png'),
					txt: '海运',
					engTxt: 'shipping'
				}, {
					icon: require('@/assets/home/land_transportation.png'),
					txt: '陆运（泰国）',
					engTxt: 'Land transportation'
				}, {
					icon: require('@/assets/home/shopping.png'),
					txt: '代购',
					engTxt: 'shopping'
				}],
				list: [],
				data:{}
			};
		},
		mounted() {
			this.homeIndex()
			this.articleList()
		},
		methods: {
			async homeIndex(){
				this.data=await this.$api.homeIndex()
			},
			async articleList() {
				this.list = await this.$api.articleList()
			},
			toOrderSystem(index) {
				this.$router.push({
					path: 'orderSystem/overbooking',
					query: {
						index
					}
				})
			}
		},
	};
</script>

<style scoped lang="scss">
	@import "assets/style/index.scss";
	.container {
		width: 100%;
		margin: 0 auto;
		background: #fff;
		position: relative;

		.top-cover {
			position: absolute;
			// top: 60px;
			width: 100%;
			height: 711px;
			background: #ededed;

			img {
				max-width: 1920px;
				min-width: 1200px;
				width: 100%;
				height: 100%;
				display: block;
				margin: 0 auto;
			}
		}
	}

	.el-dropdown-menu /deep/ .el-dropdown-menu__item {
		padding: 0 50px;
	}

	.top {
		width: 1200px;
		// height: 711px;
		position: relative;

		.cont {
			width: 1200px;
			text-align: center;
			margin-top: 330px;

			.el-input {
				width: 970px;
				height: 50px;

				/deep/ .el-input__inner {
					width: 970px;
					height: 50px;
					font-size: 1rem;
					color: #000;
				}
			}
		}

		.search-btn {
			width: 235px;
			height: 55px;
			background: url(../../assets/home/search.png);
			background-size: 100%;
			margin: 45px auto 0;
			cursor: pointer;
		}

	}

	.order {
		width: 100%;
		padding: 20px 0;
		background: #F2F6FF;
		margin-top: 150px;

		.wrap {
			width: 1200px;
			margin-top: 70px;

			.desc {
				margin-top: 10px;
				height: 60px;

				.txt {
					font-weight: bold;
					font-size: 1rem;
				}
			}

			.order-item {
				width: 25%;
				height: 250px;
				cursor: pointer;
			}
		}

		.order-item:hover {
			background: #fff;
			transition: .5s;
			box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
		}
	}

	.operation {
		width: 100%;
		background: #fff;
		padding: 80px 0;

		.section {
			width: 1000px;
			margin-top: 70px;

			.card {
				width: 100%;
				height: 350px;
				background: rgba(244, 244, 244, 0.9);
				margin-bottom: 20px;

				.item {
					height: 100%;
					width: 50%;
					overflow: hidden;
					position: relative;

				}

				.cont {
					box-sizing: border-box;
					padding: 20px;
					overflow: hidden;

					.title {
						width: 74px;
						font-size: 1.1rem;
						font-weight: bold;
						margin-bottom: 10px;

						&::after {
							content: '';
							display: block;
							width: 100%;
							height: 3px;
							background: #3F7CF8;

						}
					}

					.txt {
						width: 100%;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 7;
						overflow: hidden;
					}
				}
			}
		}
	}

	.dynamic {
		width: 100%;
		background: rgba(63, 124, 248, 0.6);
		height: 580px;
		margin-bottom: 50px;
		padding-top: 55px;

		.carousel {
			padding-top: 100px;
			width: 80%;
			height: 200px;
		}

		.card-cont {
			box-sizing: border-box;
			height: 350px;
			padding: 50px 20px;
			background: #fff;
			color: #000;
			flex: 1;

			.card-title {
				height: 29px;
				font-size: 1.6rem;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #000;
				padding-bottom: 30px;
			}

			.desc {
				width: 100%;
				height: 150px;
				margin-bottom: 30px;
			}

			.btn {
				width: 206px;
				height: 47px;
				line-height: 47px;
				background: #3F7CF8;
				color: #fff;
				border-radius: 2px;
				text-align: center;
			}
		}
	}

	.el-carousel__item h3 {
		// color: #000;
		font-size: 1rem;
		line-height: 200px;
		margin: 0;
	}



	.el-carousel__item--card {
		width: 50%;
	}

	.el-carousel__item:nth-child(2n) {
		// background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		// background-color: #d3dce6;
	}
</style>
